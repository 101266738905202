/*
 * @Description: 用户接口类型
 * @Author: Mars
 * @Date: 2023-11-20 11:13:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-08 09:41:20
 */

/** 登录参数 */
export interface ILoginParams {
  /** 用户名 */
  username: string;
  /** 密码 */
  password: string;
}
/** mfa配置信息 */
export interface IGetMFAListRes {
  /** 是否启用 True-是 False-否 */
  enable: boolean;
  /** ID */
  id: string;
  /** MFA方法类型: 1-谷歌身份验证器 2-邮箱 */
  methodType: number;
}
/** 登录响应 */
export interface ILoginResp {
  /** 凭证 */
  token: string;
  /** 用户类型 */
  userType: string;
  /** 是否需要mfa验证 */
  needMfa: boolean;
  /** mfa配置信息列表 */
  mfaCfs: IGetMFAListRes[];
  /** 编码 */
  code: string;
  /** 客户端id */
  clientId: string;
}

/** 菜单类型 */
export enum MenuType {
  /** 目录 */
  directory = 0,
  /** 页面 */
  page = 1,
  /** 外链 */
  link = 2,
}

/** 接口返回菜单 */
export interface IMenuItem {
  id: string;
  /** 父级id */
  parentId: string;
  /** 图标 */
  icon?: string;
  /** 路由地址 */
  menuUrl: string;
  /** 菜单名称 */
  menuName: string;
  /** 菜单类型 */
  menuType: MenuType;
  /** 资源地址 */
  componentUrl?: string;
  /** 微应用名字 */
  systemWebCode?: string;
  /** 子级 */
  children?: IMenuItem[];
  /** 菜单国际化 */
  menuNameDic: { [key: string]: string };
  /** 拓展字段 */
  extra?: string;
}

/** 获取用户菜单响应 */
export interface IGetMenuResp {
  mainMenu?: IMenuItem;
  /** 菜单列表 */
  menu: IMenuItem[];
  /** 权限列表 */
  permission: string[];
  /** 微应用列表 */
  webSystem: IMicroItem[];
}

/** 微应用 */
export interface IMicroItem {
  /** 微应用名称，不能重复 */
  systemWebCode: string;
  /** 地址 */
  url: string;
  /** 应用保活 */
  keepAlive: boolean;
  /** 是否启用iframe */
  iframe: boolean;
  /** 预加载 */
  prefetch: boolean;
  /** 默认页 */
  defaultPage?: string;
  /** 基础路由 */
  baseRoute?: string;
  /** 开发环境地址 */
  devUrl: string;
}

/** 语言列表项 */
export interface ILangTypeItem {
  id: string;
  /** 语言代码 */
  lgCode: string;
  /** 语言名称 */
  lgName: string;
}

/** 国家列表项 */
export interface ICountryItem {
  /** 国际ID */
  areaId: string;
  /** 国家英文名 */
  areaEnglishName: string;
  /** 国家显示名 */
  areaFirstName: string;
}

/** 时区列表项 */
export interface ITimezoneItem {
  /** 展示名称 */
  displayName: string;
  /** 标准名称 */
  standardName: string;
}

/** 用户注册参数 */
export interface IRegisterParams {
  /** 详细地址 */
  address: string;
  /** 允许程序自动升级 0:不允许 1:允许 */
  allowAutoUpdate: number | boolean;
  /** 联系电话 */
  cellphone: string;
  /** 市 */
  cityCode?: string;
  /** 确认密码 */
  confirmPassword: string;
  /** 国家 */
  countryId: string;
  /** 安装时间 */
  installDate?: string;
  /** 语言 */
  languageCode: string;
  /** License编号 */
  licenseNo?: string;
  /** 联系人 */
  linkman: string;
  /** 密码 */
  password: string;
  /** 邮编 */
  postcode: string;
  /** sn校验码 */
  snCheckCode?: string;
  /** 州/省 */
  stateCode?: string;
  /** 系统SN */
  sysSn?: string;
  /** 时区 */
  timezone: string;
  /** 用户名 */
  userName: string;
  /** 用户类型 */
  userType: string;
}
/** 用户忘记用户名参数 */
export interface IForgetUserNameParams {
  /** 用户类型 */
  userType: string;
  /** 系统SN */
  sysSn?: string;
  /** License */
  licNo?: string;
}
/** 忘记用户名接口返回值 */
export interface IForgetUserNameRes {
  /** 接收邮件的邮箱地址 */
  email: string;
}

/** 忘记密码发送验证码参数 */
export interface IForgetPasswordEmailParams {
  /** 用户名 */
  userName: string;
}
/** 忘记密码发送验证码接口返回值 */
export interface IForgetPasswordEmailRes {
  /** 邮箱地址 */
  email: string;
}

/** 忘记密码接口参数 */
export interface IForgetPasswordParams {
  /** 用户名 */
  userName: string;
  /** 校验码 */
  code: string;
}
/** 忘记密码接口返回值 */
export interface IForgetPasswordRes {
  /** 忘记密码 url 链接上的 Hour */
  hour: string;
  /** 忘记密码 url 链接上的 UserId */
  userId: string;
}

/** 忘记密码重置密码参数 */
export interface IForgetPasswordResetParams {
  /** 确认密码 */
  confirmPassword: string;
  /** hour */
  hour?: string;
  /** 密码 */
  password: string;
  /** 用户ID */
  userId?: string;
  /** 用户名 */
  username: string;
}
/** 注册激活接口字段 */
export interface IRegisterActivationParams {
  /** License */
  licenseNo?: string;
  /** 系统SN */
  sysSn?: string;
  /** 用户主键 */
  userId?: string;
  /** 用户类型 */
  userType?: string;
}

/** 注册分享用户接口参数 */
export interface IShareUserRegisterParams {
  /** 确认密码 */
  confirmPassword: string;
  /** 有效天数 */
  days?: string | number;
  /** 分享时间 */
  hour?: string;
  /** 新密码 */
  password: string;
  /** 分享人 */
  sharer?: string;
  /** 分享SN */
  sn?: string;
  /** 用户名 */
  userName: string;
}
/** 注册分享用户链接是否可靠 */
export interface IcheckShareLinkParams {
  /** 天数 */
  days?: string;
  /** 分享时间 */
  hour?: string;
  /** 分享人 */
  sharer?: string;
  /** 分享SN */
  sn?: string;
}
/** 单台系统打开远程升级 */
export interface IOpenRemoteUpgradeParams {
  /** 升级策略id */
  strategyId: string;
  /** 系统Id */
  sysId: string;
}

/** 验证token是否有效返回内容 */
export interface IAccessRes {
  code: string;
  data: boolean;
  message: string;
}
